.container {
  width: 100%;
  height: 100%;
  padding-inline: 24px;
  padding-bottom: 34px;
  box-sizing: border-box;
  overflow: hidden auto;
}

.policy {
  --gray-100: #f4f4f4;
  --gray-200: #d9d9d9;
  --gray-300: #818181;
  --gray-400: #666666;
  --gray-500: #6d6d77;
  --gray-600: #484850;
  --gray-700: #303137;
  --gray-800: #0c0d10;
}

/* margin */
.mg-24 {
  margin-bottom: 24px;
}
.mg-19 {
  margin-bottom: 19px;
}
.mg-13 {
  margin-bottom: 13px;
}
.mg-9 {
  margin-bottom: 9px;
}
.mg-5 {
  margin-bottom: 5px;
}

/* text */
.h-1 {
  color: var(--gray-200);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
}

.h-2 {
  color: var(--gray-300);
  font-size: 15px;
  font-weight: 700;
  line-height: 1.46667em;
  letter-spacing: -0.02em;
}

.h-3 {
  color: var(--gray-300);
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
}

.txt-1 {
  display: block;
  color: var(--gray-300);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33333em;
  letter-spacing: -0.02em;
}

.txt-2 {
  color: var(--gray-300);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
}

/* table */
.table {
  width: 100%;
  border: 1px solid var(--gray-400);
  box-sizing: border-box;
}

.table thead {
  background: var(--gray-600);
}

.table tr:not(.btm-line-none) {
  border-bottom: 1px solid var(--gray-400);
}

.table :is(th, td) {
  text-align: center;
  vertical-align: middle;
  padding-block: 6px;
  padding-inline: 4px;
  box-sizing: border-box;
}

.left-line {
  border-left: 1px solid var(--gray-400);
}

.mg-l {
  padding-left: 7px;
}
